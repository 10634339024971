// extracted by mini-css-extract-plugin
export var active = "Slideshow-module--active--f174a";
export var fadein = "Slideshow-module--fadein--5a7f5";
export var fadeout = "Slideshow-module--fadeout--80b81";
export var homeHeaderWrapper = "Slideshow-module--homeHeaderWrapper--cb4b1";
export var homeHeight = "Slideshow-module--homeHeight--8d4ff";
export var homeSubtitleBig = "Slideshow-module--homeSubtitleBig--bb305";
export var homeTitle = "Slideshow-module--homeTitle--14491";
export var notactive = "Slideshow-module--notactive--8986d";
export var slideshow = "Slideshow-module--slideshow--e3f35";
export var subTitle = "Slideshow-module--subTitle--65d7e";