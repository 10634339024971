import React, { useState, useEffect } from 'react';
import * as styles from './Slideshow.module.css';

interface Image {
  src: string;
  alt: string;
}

interface SlideshowProps {
  title: string;
  subtitle: string;
  images: Image[];
}

const Slideshow: React.FC<SlideshowProps> = ({ images, title, subtitle }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const interval: number = 5000;

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentIndex === images.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, interval);

    return () => clearInterval(timer);
  }, [currentIndex, images.length]);

  return (
    <>
      <div className={styles.homeHeaderWrapper}>
        <div className={styles.slideshow}>
          {images.map((image, index) => (
            <img
              width="100%"
              key={index}
              src={image.src}
              className={
                index === currentIndex ? styles.active : styles.notactive
              }
              alt={image.alt}
            />
          ))}
        </div>
      </div>
      <div className={styles.homeHeight}>
        <div className={styles.homeTitle}>
          <img src="/nagaoka2024/font-logo.png" />
        </div>
        <div className={styles.subTitle}>{subtitle}</div>
      </div>
    </>
  );
};

export default Slideshow;
